<template>
  <div class="admin">
    <div class="mianbao">
      <div class="breadcrumb">
        <span style="color: #fff"> 综合除治> </span>
        <span style="color: #016ae0"> 除治修改 </span>
      </div>
    </div>
    <div class="search">
      <div class="top">
        疫木编号：
        <a-input
          v-model="form.treeId"
          style="
            width: 15%;
            margin-right: 40px;
            background-color: transparent;
            color: #fff;
          "
          placeholder="请输入编号"
        ></a-input>
        创建时间：
        <a-range-picker
          :placeholder="['开始时间', '结束时间']"
          style="margin-right: 2%"
          @change="onChange"
        />
        作业人员：
        <a-select
          allowClear
          v-model="form.groupId"
          style="
            width: 120px;
            margin-right: 40px;
            background-color: transparent;
          "
        >
          <a-select-option
            :value="item.groupId"
            v-for="(item, index) in groupList"
            :key="index"
          >
            {{ item.groupName }}
          </a-select-option>
        </a-select>
        村名：
        <a-input
          v-model="form.address"
          style="
            width: 15%;
            margin-right: 40px;
            background-color: transparent;
            color: #fff;
          "
          placeholder="请输入村名"
        ></a-input>
      </div>
      <div class="bottom">
        林业小班：
        <a-input
          v-model="form.treeClass"
          style="
            width: 15%;
            margin-right: 40px;
            background-color: transparent;
            color: #fff;
          "
          placeholder="请输入林业小班"
        ></a-input>
        上传用户：
        <a-input
          v-model="form.createBy"
          style="
            width: 15%;
            margin-right: 40px;
            background-color: transparent;
            color: #fff;
          "
          placeholder="请输入上传用户"
        ></a-input>
        <a-button type="primary" :disabled="disabled" @click="handleLists"
          >查询</a-button
        >
      </div>
    </div>
    <div class="content">
      <div class="erweima">
        <div class="list">
          <a-spin class="spin" :spinning="spinning" />
          <div style="display: flow-root; height: 42px; width: 100%">
            <div class="l_list">疫木除治列表</div>
            <div class="l_column">
              <a-button class="l_sort" @click="sortClick">
                <div class="l_type">
                  <div>排序</div>
                  <div>
                    <div class="l_up"><a-icon type="caret-up" /></div>
                    <div class="l_below"><a-icon type="caret-down" /></div>
                  </div>
                </div>
              </a-button>
            </div>
          </div>
          <vue-scroll :ops="ops" style="width: 100%">
            <AmendTable :list="list" />
            <div class="fenye">
             <span style="margin-right: 15px;">共{{total}}条</span>
              <a-config-provider :locale="locale">
                <a-pagination
                  @change="onShowSizeChange"
                  @showSizeChange="showSizeChange"
                  v-model="form.pageNum"
                  :pageSizeOptions="pageSizeOptions"
                  :pageSize="form.pageSize"
                  :total="total"
                  show-less-items
                  :showSizeChanger="true"
                />
              </a-config-provider>
            </div>
          </vue-scroll>
        </div>
      </div>
    </div>
    <!-- 项目查询 -->
    <Cebian
      :show="show"
      @handleShow="handleShow"
      @handleProject="handleProject"
    />
  </div>
</template>

<script>
import zhCN from "ant-design-vue/lib/locale-provider/zh_CN";
import Cebian from "../../components/cebian.vue"; //项目查询
import AmendTable from "./components/amendTable.vue"; // 除治列表
import axios from "axios";
export default {
  data() {
    return {
      token: "",
      groupIdList: sessionStorage.getItem("groupId"), // 工作组id
      username: sessionStorage.getItem("username"),
      show: false, //项目查询展示/隐藏
      form: {
        //查询信息
        pageNum: 1,
        pageSize: 10,
        isAccept: 0,
        projectIdList: sessionStorage.getItem("projectId"),
      },
      total: 0, //列表总数
      lastPage: 1,
      locale: zhCN, //国际化 设置成中文
      pageSizeOptions: ["10", "15", "20", "25"],
      list: [], // 除治列表信息
      spinning: false, //数据加载动画
      groupList: [], //作业人员列表/工作组
      order: "ASC", //倒序/正序
      disabled: false, //点击查询时接口未执行完毕
      options: [], //地区
      //滚动条
      ops: {
        vuescroll: {},
        scrollPanel: {},
        rail: {
          keepShow: true,
        },
        bar: {
          hoverStyle: true,
          onlyShowBarOnScroll: false, //是否只有滚动的时候才显示滚动条
          background: "#F5F5F5", //滚动条颜色
          opacity: 0.5, //滚动条透明度
          "overflow-y": "hidden",
        },
      },
      treeIds: [],
    };
  },
  components: {
    Cebian,
    AmendTable,
  },
  mounted() {
    this.handleList();
    this.handleGroup();
    this.getArea(); // 地区
    this.token = sessionStorage.getItem("token");
  },
  methods: {
    // 获取工作组/作业人员
    handleGroup() {
      this.$axios(
        this.api + `/group/list?groupIdList=${this.groupIdList}`
      ).then((res) => {
        this.groupList = res.data.list;
      });
    },
    // 项目查询
    handleProject(e) {
      this.form.projectIdList = e;
      this.handleList();
    },
    handleShow(show) {
      this.show = show;
    },
    // 查询
    handleLists() {
      this.disabled = true;
      if (this.form.pageNum !== 1) {
        this.form.pageNum = this.lastPage;
        this.handleList();
      } else {
        this.handleList();
      }
    },
    //  疫木除治列表
    handleList() {
      this.spinning = true;
      this.$axios(this.api + "/elimination/hy/unaccept/list", { params: this.form }).then(
        (res) => {
          if (res.code === 200) {
            this.lastPage = res.data.pages;
            this.total = res.data.total;
            this.list = res.data.list;
            this.spinning = false;
            this.disabled = false;
          }
        }
      );
    },
    // 创建时间
    onChange(value, mode) {
      this.form.startTime = mode[0];
      this.form.endTime = mode[1];
    },
    // 分页
    onShowSizeChange(pageNum, pageSize) {
      this.form.pageNum = pageNum;
      this.handleList();
    },
    // 每页几条？
    showSizeChange(current, size) {
      this.form.pageSize = size;
      this.handleList();
    },
    // 获取地区
    getArea() {
      this.$axios.get(this.api + "/project/search").then((res) => {
        if (res.code === 200) {
          this.options = this.listToTree(res.data);
        }
      });
    },
    listToTree(list, parentId = "") {
      return list
        .filter((item) => item.parentId === parentId)
        .map((item) => ({
          ...item,
          label: item.orgName,
          value: item.orgName,
          children: this.listToTree(list, item.id),
        }));
    },
    cancel() {
      this.treeIds = []
      this.data = null
    },
    // 排序
    async sortClick() {
      let form = this.form;
      if (this.order === "ASC") {
        this.order = "DESC";
        form.order = this.order
        const res = await this.$axios.get(this.api + "/elimination/list", { params: form });
        return (this.list = res.data.list);
      } else if (this.order === "DESC") {
        this.order = "ASC";
        form.order = this.order
        const res = await this.$axios.get(this.api + "/elimination/list", { params: form });
        return (this.list = res.data.list);
      }
    }, 
  },
};
</script>

<style scoped="scoped">
div /deep/.ant-pagination-item-ellipsis {
  color: #fff !important;
}
div /deep/.ant-select-selection {
  color: #fff;
  background-color: rgba(7, 23, 46, 1);
}
div /deep/.ant-input {
  color: #fff;
  background-color: rgba(7, 23, 46, 1);
}
div /deep/.ant-select-arrow {
  color: rgb(249 240 240 / 91%);
}
div /deep/.ant-calendar-range-picker-separator {
  color: rgb(249 240 240 / 91%);
}
div /deep/.duoxuan > div[data-v-21316321] {
  background-color: rgba(240, 240, 240, 0.1);
  color: #ffffff;
}
.admin {
  width: 100%;
  height: 100%;
  background-color: #f5f5f5;
  padding: 10px 40px 20px 40px;
  background: url(../../assets/bg01.jpg) no-repeat;
  background-size: 100% 100%;
}
.mianbao {
  height: 40px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* background-color: yellow; */
}
.search {
  width: 100%;
  height: 140px;
  border: 1px solid #016ae0;
  border-radius: 8px;
  color: #ffffff;
  /* background-color: #fff; */
  margin-top: 10px;
  text-align: left;
  /* display: flex; */
  /* align-items: center; */
  padding: 0 20px;
}
.search > div {
  height: 70px;
  display: flex;
  align-items: center;
}
.content {
  width: 100%;
  height: calc(100% - 210px);
  /* background-color: #fff; */
  border: 1px solid #016ae0;
  border-radius: 8px;
  margin-top: 20px;
  padding: 11px;
  color: #ffffff;
}
.button {
  text-align: left;
}
.erweima {
  width: 100%;
  /* margin-top: 8px; */
  height: 100%;
}
.list {
  display: flex;
  flex-wrap: wrap;
  height: calc(100% - 40px);
  /* position: relative; */
}
.spin {
  position: absolute;
  top: 40%;
  left: 50%;
}
.item {
  width: 23.5%;
  height: calc(50% - 21px);
  margin-bottom: 10px;
}
.fenye {
  height: 32px;
  margin-top: 8px;
  text-align: right;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.yuan {
  width: 15px;
  height: 15px;
  border: 1px solid #005ea1;
  border-radius: 50%;
}
.item_center {
  border: 1px solid #005ea1;
  width: 100%;
  margin-top: 15px;
  height: calc(100% - 30px);
}
.number {
  height: 60px;
  display: flex;
  padding: 0 20px;
  align-items: center;
  font-size: 18px;
}
.img {
  border-top: 1px solid #005ea1;
  height: calc(100% - 60px);
  display: flex;
  align-items: center;
  justify-content: center;
}
.img img {
  height: 80%;
  width: auto;
}
/* ------------------------------------------ */
.l_list {
  display: flex;
  float: left;
  align-items: center;
  padding: 0 0rem 10px 4px;
  height: 100%;
  font-size: 16px;
}
.l_column {
  padding: 0 10px 10px 0;
  display: flex;
  float: right;
  align-items: center;
  height: 100%;
}
.l_type {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2rem;
}
.l_column .l_sort {
  margin: 0 1rem;
  border: 1px solid #0099ff;
  background: transparent;
  color: #0099ff;
}
.l_column .l_derive {
  color: #ffffff;
  border: 1px solid #0099ff;
  background: #0066ff;
}
.l_up {
  height: 10px;
}
.l_below {
  height: 19px;
}
div /deep/ .el-dialog {
  margin-top: 1vh !important;
}
div /deep/ .el-transfer-panel__body {
  height: 61vh;
}
div /deep/ .el-transfer-panel__list.is-filterable {
  height: 56vh;
}
</style>
