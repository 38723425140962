import { render, staticRenderFns } from "./listAmend.vue?vue&type=template&id=dc4d2340&scoped=true&"
import script from "./listAmend.vue?vue&type=script&lang=js&"
export * from "./listAmend.vue?vue&type=script&lang=js&"
import style0 from "./listAmend.vue?vue&type=style&index=0&id=dc4d2340&prod&scoped=scoped&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dc4d2340",
  null
  
)

export default component.exports