<template>
  <div class="table">
    <div class="header">
      <div class="list">
        <div
          class="item"
          :style="{ width: item.width + '%' }"
          v-for="(item, index) in columns"
          :key="index"
        >
          {{ item.title }}
        </div>
      </div>
    </div>
    <div class="table_body">
      <div class="body" v-for="item in list" :key="item.treeId">
        <div class="list">
          <div
            class="item"
            style="width: 6%"
            v-clipboard:copy="item.treeId"
            v-clipboard:success="onCopy"
            v-clipboard:error="onError"
          >
            {{ item.treeId }}
          </div>
          <div
            class="item"
            style="width: 4%"
            v-clipboard:copy="item.personnel"
            v-clipboard:success="onCopy"
            v-clipboard:error="onError"
          >
            {{ item.personnel }}
          </div>
          <div
            class="item"
            style="width: 6%"
            v-clipboard:copy="item.longitude"
            v-clipboard:success="onCopy"
            v-clipboard:error="onError"
          >
            {{ item.longitude }}
          </div>
          <div
            class="item"
            style="width: 6%"
            v-clipboard:copy="item.latitude"
            v-clipboard:success="onCopy"
            v-clipboard:error="onError"
          >
            {{ item.latitude }}
          </div>
          <div
            class="item"
            style="width: 4%"
            v-clipboard:copy="item.diameter"
            v-clipboard:success="onCopy"
            v-clipboard:error="onError"
          >
            {{ item.diameter }}
          </div>
          <div
            class="item"
            style="width: 4%"
            v-clipboard:copy="item.address"
            v-clipboard:success="onCopy"
            v-clipboard:error="onError"
          >
            {{ item.address }}
          </div>
          <div
            class="item"
            style="width: 10%"
            v-clipboard:copy="item.treeClass"
            v-clipboard:success="onCopy"
            v-clipboard:error="onError"
          >
            {{ item.treeClass }}
          </div>
          <div class="item" style="width: 6%">
            {{ item.sample | sampleChange }}
          </div>
          <div class="item" style="width: 6%">
            {{ item.identify | identifyChange }}
          </div>
          <div
            class="item"
            :style="
              item.isAccept === 1
                ? 'width: 6%;color:#06d5ff;'
                : 'width: 6%;color:#ff0000;'
            "
          >
            {{ item.isAccept | isAcceptChange }}
          </div>
          <div class="item" style="width: 10%">
            {{ item.projectName }}
          </div>
          <div
            class="item"
            style="width: 10%"
            v-clipboard:copy="item.createTime"
            v-clipboard:success="onCopy"
            v-clipboard:error="onError"
          >
            {{ item.createTime }}
          </div>
          <div class="item" style="width: 7%">
            {{ item.groupName }}
          </div>
          <div class="item" style="width: 7%">
            <div
              class="yuan"
              :style="{
                'background-color':
                  item.status == 0
                    ? '#ff0000'
                    : item.status == '2'
                    ? '#09f'
                    : '#09f',
              }"
            ></div>
            {{
              item.status == "0"
                ? "未除治"
                : item.status == "2"
                ? "已除治"
                : "已除治"
            }}
          </div>
          <div
            class="item"
            :style="
              item.examine === 1
                ? 'width: 6%;color:#06d5ff;'
                : 'width: 6%;color:#ff0000;'
            "
          >
            {{ item.examine === 1 ? "已通过" : "未通过" }}
          </div>
          <div class="item" style="width: 7%">
            {{ item.examineUser }}
          </div>
          <div class="item remark" style="width: 7%">
            {{ item.remark }}
          </div>
          <div class="item item1" style="width: 10%; display: block">
            <div class="yi" v-if="item.status == '0'">
              <div class="" style="color: #ff0000">未上传</div>
              <div class="" style="color: #999">暂无</div>
            </div>
            <div
              class="wei"
              v-else
              style="display: flex; justify-content: space-evenly"
            >
              <div class="" style="color: #09f" @click="amendClick(item)">
                修改
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      title="修改"
      :visible.sync="dialogVisible"
      width="30%"
      style="text-align: left"
    >
      <el-form ref="form" :model="amend" label-width="80px">
        <el-form-item label="疫目编号">
          <el-input style="width: 80%" v-model="amend.treeId"></el-input>
        </el-form-item>
        <el-form-item label="工作组">
          <el-select
            filterable
            v-model="amend.groupId-0"
            @change="onChange"
            class="t-job"
            style="width: 80%; margin-right: 40px"
          >
            <el-option
              :value="item.groupId"
              :label="item.groupName"
              v-for="(item, index) in groupList"
              :key="index"
            >
              {{ item.groupName }}
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="经度">
          <el-input
            style="width: 80%"
            v-model="amend.longitude"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="纬度">
          <el-input
            clearable
            style="width: 80%; margin-right: 10px"
            v-model="amend.latitude"
          ></el-input>
          <el-button type="primary" plain size="mini" @click="selectClick"
            >选择</el-button
          >
        </el-form-item>
        <el-form-item label="检索" v-if="searching">
          <el-input
            clearable
            style="width: 80%; margin-right: 10px"
            v-model="inquire"
            @keyup.enter.native="searchKeyWord"
          ></el-input>
          <el-button type="primary" plain size="mini" @click="searchKeyWord"
            >查询</el-button
          >
          <!-- 搜索结果选择窗  -->
          <div class="map_search_result" v-if="showsearchResult">
            <ul>
              <li
                @click="markerResult(item)"
                v-for="(item, index) in poiList"
                :key="index"
              >
                {{ item.name }}
              </li>
            </ul>
          </div>
          <div :style="inquireStyle">
            <div id="container" style="width: 100%; height: 100%"></div>
          </div>
        </el-form-item>
        <el-form-item label="地径">
          <el-input
            style="width: 80%"
            v-model="amend.diameter"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="村名">
          <el-select
            filterable
            v-model="amend.address"
            class="t-job"
            style="width: 80%; margin-right: 40px"
          >
            <el-option
              v-for="(item, index) in villageList"
              :key="index"
              :value="item.orgName"
              :label="item.orgName"
            >
              {{ item.orgName }}
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="林业小班">
          <el-input
            style="width: 80%"
            v-model="amend.treeClass"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="除治方式">
          <el-select
            filterable
            v-model="amend.disposal"
            class="t-job"
            style="width: 80%; margin-right: 40px"
          >
            <el-option
              :value="item"
              :label="item"
              v-for="(item, index) in disposalList"
              :key="index"
            >
              {{ item }}
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="上传用户">
          <el-select
            filterable
            v-model="amend.personnel"
            class="t-job"
            style="width: 80%; margin-right: 40px"
          >
            <el-option
              :value="item.username"
              :label="item.username"
              v-for="(item, index) in userList"
              :key="index"
            >
              {{ item.username }}
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="上传时间">
          <el-date-picker
            v-model="amend.createTime"
            value-format="yyyy-MM-dd HH:mm:ss"
            type="datetime"
            placeholder="选择日期时间"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="砍伐前">
          <el-upload
            ref="upload"
            action="#"
            :limit="2"
            :file-list="amend.beforeImg"
            list-type="picture-card"
            :on-change="beforeChange"
            :on-remove="beforeRemove"
            :auto-upload="false"
          >
            <i slot="default" class="el-icon-plus"></i>
          </el-upload>
        </el-form-item>
        <el-form-item label="封桩后">
          <el-upload
            ref="upload"
            action="#"
            :limit="2"
            :file-list="amend.encapsulationImg"
            list-type="picture-card"
            :on-change="encapsulationChange"
            :on-remove="encapsulationRemove"
            :auto-upload="false"
          >
            <i slot="default" class="el-icon-plus"></i>
          </el-upload>
        </el-form-item>
        <el-form-item label="地径测量">
          <el-upload
            ref="upload"
            action="#"
            :limit="2"
            :file-list="amend.diameterImg"
            list-type="picture-card"
            :on-change="diameterChange"
            :on-remove="diameterRemove"
            :auto-upload="false"
          >
            <i slot="default" class="el-icon-plus"></i>
          </el-upload>
        </el-form-item>
        <el-form-item label="奥维定位">
          <el-upload
            ref="upload"
            action="#"
            :limit="2"
            :file-list="amend.oveyImg"
            list-type="picture-card"
            :on-change="oveyChange"
            :on-remove="oveyRemove"
            :auto-upload="false"
          >
            <i slot="default" class="el-icon-plus"></i>
          </el-upload>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="affirmClick">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import moment from "moment";
import { gcj02_to_wgs84 } from "../../../utils/wgs84_to_gcj02.js";

export default {
  props: {
    list: Array,
  },
  inject: ["reload"],
  data() {
    return {
      dialogVisible: false,
      amend: {},
      columns: [
        {
          title: "疫木编号",
          dataIndex: "treeId",
          key: "treeId",
          width: "6",
        },
        {
          title: "上传用户",
          dataIndex: "personnel",
          key: "personnel",
          width: "4",
        },
        {
          title: "经度",
          dataIndex: "longitude",
          key: "longitude",
          width: "6",
        },
        {
          title: "纬度",
          dataIndex: "latitude",
          key: "latitude",
          width: "6",
        },
        {
          title: "地径(cm)",
          dataIndex: "diameter",
          key: "diameter",
          width: "4",
        },
        {
          title: "村名",
          dataIndex: "address",
          key: "address",
          width: "4",
        },
        {
          title: "林业小班",
          key: "treeClass",
          dataIndex: "treeClass",
          width: "10",
        },
        {
          title: "是否取样",
          key: "sample",
          dataIndex: "sample",
          width: "6",
        },
        {
          title: "是否认定",
          key: "identify",
          dataIndex: "identify",
          width: "6",
        },
        {
          title: "是否验收",
          key: "isAccept",
          dataIndex: "isAccept",
          width: "6",
        },
        {
          title: "所属项目",
          key: "projectName",
          dataIndex: "projectName",
          width: "10",
        },
        {
          title: "创建时间",
          key: "createTime",
          dataIndex: "createTime",
          width: "10",
        },
        {
          title: "作业人员",
          key: "groupName",
          dataIndex: "groupName",
          width: "7",
        },
        {
          title: "状态",
          key: "status",
          dataIndex: "status",
          width: "7",
        },
        {
          title: "核验状态",
          key: "examine",
          dataIndex: "examine",
          width: "7",
        },
        {
          title: "核验者",
          key: "examineUser",
          dataIndex: "examineUser",
          width: "7",
        },
        {
          title: "备注",
          key: "remark",
          dataIndex: "remark",
          width: "7",
        },
        {
          title: "操作",
          key: "action",
          dataIndex: "tags",
          width: "10",
        },
      ],
      groupList: [], // 工作组
      villageList: [], // 村名
      userList: [], // 用户
      disposalList: ['焚烧','掩埋','粉碎','就地封包','其他'], // 除治方式
      groupIdList: sessionStorage.getItem("groupId"),
      modify: sessionStorage.getItem("modify"),
      treeOldIdz: "", //旧id
      inquire: "",
      inquireStyle: "",
      searching: false,
      map: null, //地图初始化
      zoom: 6, //地图初始展示级别
      poiList: [],
      address: "",
      showsearchResult: false,
      autoCompleteComponent: null,
      placeSearchComponent: null,
      imgages: [],
    };
  },
  filters: {
    sampleChange(e) {
      return e == 1 ? "已取样" : "未取样";
    },
    identifyChange(e) {
      return e == 1 ? "已认定" : "未认定";
    },
    isAcceptChange(e) {
      return e == 1 ? "已验收" : "未验收";
    },
  },
  methods: {
    // 复制成功
    onCopy() {
      this.$message.success("复制成功");
    },
    // 复制失败
    onError() {
      this.$message.info("复制失败");
    },
    // 获取工作组列表
    handleGroup() {
      this.$axios(this.api + "/group/list").then((res) => {
        this.groupList = res.data.list;
      });
    },
    onChange(e) {
      this.amend.groupId = e;
      this.villageClick();
    },
    // 获取村列表
    villageClick() {
      this.$axios(this.api + `/group/org/${this.amend.groupId}`).then((res) => {
        this.villageList = res.data;
      });
    },
    // 获取用户列表
    userClick() {
      this.$axios(this.api + "/user/select").then((res) => {
        this.userList = res.data;
      });
    },
    // 修改
    amendClick(e) {
      this.treeOldId = e.treeId;
      this.searching = false;
      this.inquire = "";
      this.imgages = [];
      this.$axios(this.api + "/elimination/accurate", {
        params: {
          treeId: e.treeId,
        },
      }).then((res) => {
        this.amend = res.data;
        // 砍伐前
        let beforeImg = [];
        this.amend.beforeImgList = [];
        if (res.data.beforeImg.length !== 0) {
          res.data.beforeImg.map((item) => {
            this.amend.beforeImgList.push(item.imgName);
            let beforeFile = null;
            this.getImageFileFromUrl(
              this.api + "/" + item.imgPrefix + item.imgSrc,
              item.imgName
            )
              .then((response) => {
                beforeFile = response;
                beforeFile.url = URL.createObjectURL(beforeFile);
                beforeImg.push(beforeFile);
                this.imgages.push(beforeFile);
              })
              .catch((e) => {
                console.error(e);
              });
          });
        }
        // 封桩后
        let encapsulationImg = [];
        this.amend.encapsulationImgList = [];
        if (res.data.encapsulationImg.length !== 0) {
          res.data.encapsulationImg.map((item) => {
            this.amend.encapsulationImgList.push(item.imgName);
            let encapsulationFile = null;
            this.getImageFileFromUrl(
              this.api + "/" + item.imgPrefix + item.imgSrc,
              item.imgName
            )
              .then((response) => {
                encapsulationFile = response;
                encapsulationFile.url = URL.createObjectURL(encapsulationFile);
                encapsulationImg.push(encapsulationFile);
                this.imgages.push(encapsulationFile);
              })
              .catch((e) => {
                console.error(e);
              });
          });
        }
        // 地径测量
        let diameterImg = [];
        this.amend.diameterImgList = [];
        if (res.data.diameterImg.length !== 0) {
          res.data.diameterImg.map((item) => {
            this.amend.diameterImgList.push(item.imgName);
            let diameterFile = null;
            this.getImageFileFromUrl(
              this.api + "/" + item.imgPrefix + item.imgSrc,
              item.imgName
            )
              .then((response) => {
                diameterFile = response;
                diameterFile.url = URL.createObjectURL(diameterFile);
                diameterImg.push(diameterFile);
                this.imgages.push(diameterFile);
              })
              .catch((e) => {
                console.error(e);
              });
          });
        }
        // 奥维定位
        let oveyImg = [];
        this.amend.oveyImgList = [];
        if (res.data.oveyImg.length !== 0) {
          res.data.oveyImg.map((item) => {
            this.amend.oveyImgList.push(item.imgName);
            let oveyFile = null;
            this.getImageFileFromUrl(
              this.api + "/" + item.imgPrefix + item.imgSrc,
              item.imgName
            )
              .then((response) => {
                oveyFile = response;
                oveyFile.url = URL.createObjectURL(oveyFile);
                oveyImg.push(oveyFile);
                this.imgages.push(oveyFile);
              })
              .catch((e) => {
                console.error(e);
              });
          });
        }
        this.amend.beforeImg = beforeImg;
        this.amend.encapsulationImg = encapsulationImg;
        this.amend.diameterImg = diameterImg;
        this.amend.oveyImg = oveyImg;
        this.villageClick();
        this.dialogVisible = true;
      });
    },
    // 图像转file
    getImageFileFromUrl(url, imageName) {
      return new Promise((resolve, reject) => {
        let blob = null;
        let imgFile = null;
        let xhr = new XMLHttpRequest();
        xhr.open("GET", url);
        xhr.setRequestHeader("Accept", "image/png");
        xhr.responseType = "blob";
        xhr.onload = () => {
          blob = xhr.response;
          imgFile = new File([blob], imageName, { type: "image/png" });
          resolve(imgFile);
        };
        xhr.onerror = (e) => {
          reject(e);
        };
        xhr.send();
      });
    },
    // 砍伐前
    // 上传成功
    beforeChange(file, fileList) {
      file.raw.url = file.url;
      this.amend.beforeImg.push(file.raw);
      this.amend.beforeImgList.push(file.raw.name);
      this.imgages.push(file.raw);
    },
    // 删除图像
    beforeRemove(file, fileList) {
      this.amend.beforeImg = this.amend.beforeImg.filter(function (item) {
        return file.uid !== item.uid;
      });
      this.imgages = this.imgages.filter(function (item) {
        return file.uid !== item.uid;
      });
      this.amend.beforeImgList = this.amend.beforeImgList.filter(function (
        item
      ) {
        return file.name !== item;
      });
    },
    // 封桩后
    // 上传成功
    encapsulationChange(file, fileList) {
      file.raw.url = file.url;
      this.amend.encapsulationImg.push(file.raw);
      this.amend.encapsulationImgList.push(file.raw.name);
      this.imgages.push(file.raw);
    },
    // 删除图像
    encapsulationRemove(file, fileList) {
      this.amend.encapsulationImg = this.amend.encapsulationImg.filter(
        function (item) {
          return file.uid !== item.uid;
        }
      );
      this.imgages = this.imgages.filter(function (item) {
        return file.uid !== item.uid;
      });
      this.amend.encapsulationImgList = this.amend.encapsulationImgList.filter(
        function (item) {
          return file.name !== item;
        }
      );
    },
    // 地径测量
    // 上传成功
    diameterChange(file, fileList) {
      file.raw.url = file.url;
      this.amend.diameterImg.push(file.raw);
      this.amend.diameterImgList.push(file.raw.name);
      this.imgages.push(file.raw);
    },
    // 删除图像
    diameterRemove(file, fileList) {
      this.amend.diameterImg = this.amend.diameterImg.filter(function (item) {
        return file.uid !== item.uid;
      });
      this.imgages = this.imgages.filter(function (item) {
        return file.uid !== item.uid;
      });
      this.amend.diameterImgList = this.amend.diameterImgList.filter(function (
        item
      ) {
        return file.name !== item;
      });
    },
    // 奥维定位
    // 上传成功
    oveyChange(file, fileList) {
      file.raw.url = file.url;
      this.amend.oveyImg.push(file.raw);
      this.amend.oveyImgList.push(file.raw.name);
      this.imgages.push(file.raw);
    },
    // 删除图像
    oveyRemove(file, fileList) {
      this.amend.oveyImg = this.amend.oveyImg.filter(function (item) {
        return file.uid !== item.uid;
      });
      this.imgages = this.imgages.filter(function (item) {
        return file.uid !== item.uid;
      });
      this.amend.oveyImgList = this.amend.oveyImgList.filter(function (item) {
        return file.name !== item;
      });
    },
    // 选择
    selectClick() {
      this.inquireStyle = "width: 80%;height: 27vh;";
      this.searching = true;
      let that = this;
      setTimeout(() => {
        this.map = new AMap.Map("container", {
          layers: [
            // 卫星
            new AMap.TileLayer.Satellite(),
            // 路网
            new AMap.TileLayer(),
          ],
          zoom: this.zoom,
        });
        //为地图注册click事件获取鼠标点击出的经纬度坐标
        this.map.on("click", function (e) {
          let adds = gcj02_to_wgs84(e.lnglat.lng, e.lnglat.lat);
          let newlongitude =
            JSON.stringify(adds).split(",")[0].replace("[", "") - 0;
          let newlatitude =
            JSON.stringify(adds).split(",")[1].replace("]", "") - 0;
          that.amend.longitude = newlongitude.toFixed(6);
          that.amend.latitude = newlatitude.toFixed(6);
        });
        this.mapSearchInit();
      }, 1500);
    },
    /** 初始化搜索 */
    mapSearchInit() {
      let autoOptions = {
        input: "tipInput",
      };
      let autoCompleteComponent = new AMap.Autocomplete(autoOptions);
      this.autoCompleteComponent = autoCompleteComponent;
      // 注册placeSearch组件
      this.placeSearchComponent = new AMap.PlaceSearch();
      this.placeSearchComponent.N.pageSize = 50;
    },
    //根据输入内容查询
    searchKeyWord() {
      let that = this;
      that.placeSearchComponent.search(that.inquire, function (status, result) {
        if (status === "complete") {
          that.poiList = result.poiList.pois;
          that.showsearchResult = true;
        } else {
          that.poiList = [];
          that.showsearchResult = false;
          that.$message.info("没有查到结果");
        }
      });
    },
    //选择搜索的内容
    markerResult(data) {
      this.showsearchResult = false;
      this.address = data.name;
      var marker = new AMap.Marker({
        position: [Number(data.location.lng), Number(data.location.lat)],
      });
      this.map.clearMap(); // 清除所有覆盖物（点标志）
      this.map.add(marker); // 添加点标志
      setTimeout(() => {
        this.map.setCenter(data.location);
        this.map.setZoom(15);
      }, 50);
      let thisPosition = {
        address: this.address,
        lng: data.location.lng,
        lat: data.location.lat,
      };
      let adds = gcj02_to_wgs84(thisPosition.lng, thisPosition.lat);
      let newlongitude =
        JSON.stringify(adds).split(",")[0].replace("[", "") - 0;
      let newlatitude = JSON.stringify(adds).split(",")[1].replace("]", "") - 0;
      this.amend.longitude = newlongitude.toFixed(6);
      this.amend.latitude = newlatitude.toFixed(6);
      this.$emit("select", thisPosition);
    },
    // 修改
    async affirmClick() {
      let formData = new FormData();
      if(!this.amend.treeId || !this.amend.groupId || !this.amend.longitude || !this.amend.latitude || !this.amend.address || !this.amend.personnel || !this.amend.createTime) {
        return this.$message.info("请完善基础信息");
      }
      if(this.amend.beforeImgList.length === 0 || this.amend.encapsulationImgList.length === 0) {
        return this.$message.info("请完善砍伐前/封桩后图像");
      }
      let data = {
        treeId: this.amend.treeId,
        treeOldId: this.treeOldId,
        groupId: this.amend.groupId,
        longitude: this.amend.longitude,
        latitude: this.amend.latitude,
        diameter: this.amend.diameter,
        address: this.amend.address,
        treeClass: this.amend.treeClass,
        personnel: this.amend.personnel,
        disposal: this.amend.disposal,
        createTime: this.amend.createTime,
      };
      formData.append("beforeImgList", this.amend.beforeImgList);
      formData.append("encapsulationImgList", this.amend.encapsulationImgList);
      formData.append("diameterImgList", this.amend.diameterImgList);
      formData.append("oveyImgList", this.amend.oveyImgList);
      this.imgages.map((item) => {
        formData.append("files", item);
      });
      const res = await this.$axios.put(
        this.api + "/elimination/hy/unaccept/update",
        formData,
        { params: data }
      );
      if (res.code === 200) {
        this.dialogVisible = false;
        this.reload();
        this.$message.success("提交成功，请手动更新");
      } else {
        this.$message.info("更新失败，请重试");
      }
    },
  },
  mounted() {
    this.handleGroup();
    this.userClick();
  },
};
</script>

<style scoped>
.table {
  width: 100%;
  height: 100%;
  /* background-color: red; */
}
.yuan {
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: red;
  margin-right: 5px;
}
/* .item1 > div {
  display: flex;
} */
.yi > div,
.wei > div {
  margin-right: 0px;
  /* width: 4em; */
}
.wei > div:hover {
  cursor: pointer;
}
.duoxuan {
  width: 40px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.duoxuan > div {
  width: 10px;
  height: 10px;
  border: 1px solid #ccc;
  background-color: #fff;
}
.header {
  display: flex;
  height: 50px;
  background-color: #1f325e;
  border-bottom: 1px solid #005ea1;
  /* align-items: center; */
}
.header .list {
  background-color: #1f325e;
  width: calc(100% - 0px);
  display: flex;
  align-items: center;
}
.table_body {
  height: calc(100% - 50px);
  /* overflow-y: auto; */
}
.table_body .body {
  display: flex;
  min-height: 50px;
  border-bottom: 1px solid #005ea1;
  /* align-items: center; */
}
.table_body .list {
  /* background-color:#1f325e ; */
  width: calc(100% - 0px);
  display: flex;
  align-items: center;
}
.item {
  display: flex;
  align-items: center;
  justify-content: center;
}
.remark {
  overflow: auto;
  display: block;
}
::-webkit-scrollbar {
  width: 8px; /* 纵向滚动条 宽度 */
  height: 8px; /* 横向滚动条 高度 */
  background-color: #c9cdd44f; /* 整体背景 */
  border-radius: 10px; /* 整体 圆角 */
}
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #c9cdd4;
}
.t-job >>> .ant-select-selection {
  background-color: transparent;
  color: #606266;
  height: 40px;
}
.t-job >>> .ant-select-selection__rendered {
  line-height: 40px;
}
.map_search_result {
  width: 80%;
  height: 27vh;
  overflow: auto;
  position: absolute;
  z-index: 999;
  background: #ffffffeb;
}
.map_search_result ul {
  list-style: none;
  padding: 0 14px;
  height: 100%;
}
.map_search_result li {
  border-bottom: 1px solid #cccccc7d;
  cursor: pointer;
}
</style>
